input:-webkit-autofill {
    background-color: transparent !important;
}

.hero {
    display: none;
}

.garden h2 {
    padding-top: 35px;
}

.hero .content {
    position: absolute;
    top: 20%;
    text-align: center;
    width: 100%;
    color: #ffffff;
}

.hero .content h4,
.hero .content p {
    text-align: center;
}

.hero .join {
    color: #ffffff;
    background: #ffa81b;
    border-radius: 20px;
    border-width: 0;
    font-weight: bold;
    height: 40px;
    padding: 10px 30px;
    text-decoration: none;
}

h2 strong {
    color: #ffa81b;
    font-weight: normal;
}

.team,
.partners,
.aboutus {
    position: relative;
    padding-top: 35px;
    background: #ffffff;
}

.team .d-block {
    color: #007bff;
}

@media (min-width: 992px) {
    .hero {
        display: block;
    }
    .hero .content {
        position: inherit;
    }
    .hero .background {
        position: absolute;
        top: 0;
        left: 0;
        width: 50%;
    }
    .hero .content {
        text-align: right;
        margin-top: 60px;
    }

    .how-it-works {
        transform: tY(0);
    }
}

@media (min-width: 576px) {
    .heroContainer {
        height: calc(100vh - 40px);
        -o-height: calc(100vh - 40px);
        -webkit-height: calc(100vh - 40px);
        -moz-height: calc(100vh - 40px);
    }
}

@media (max-width: 576px) {
    .responsive-title {
        align-content: center;
        flex-direction: column;
    }
}

.howItWorks {
    text-align: center;
    position: relative;
}
.howItWorks > .icon {
    position: relative;
    z-index: 2;
    margin: auto;
    width: 130px;
    height: 130px;
    border: 8px solid white;
    line-height: 150px;
    border-radius: 50%;
    text-align: center;
    background: #ffa81b;
    color: #ffffff;
    vertical-align: middle;
}

.dialog-box {
    background: white;
    width: 100%;
    padding: 24px;
    position: relative;
    letter-spacing: 1px;
    border-radius: 1px;
    margin-bottom: 20px;
}

.dialog-box > p {
    margin: 0;
    width: 100%;
}

.dialog-box:after {
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 22px 0px 0 45px;
    border-color: #ffffff transparent transparent transparent;
    position: absolute;
    left: 15%;
    bottom: -20px;
    content: close-quote;
    z-index: 1;
}

.info {
    width: 100%;
    height: 45%;
    position: relative;
}
