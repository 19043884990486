html body {
  direction: rtl;
  font-family: Assistant, Arial, Helvetica, sans-serif !important ;
}
.MuiTypography-body1-48,
.MuiTypography-h5-55 .jss55 .MuiTypography-h5-681 {
  font-family: Assistant, Arial, Helvetica, sans-serif !important ;
}
.container .btn .active {
  background-color: #054487;
  border-color: #054487;
}

.error-container {
  color: '#900';
}

.centerNote {
  text-align: center;
}

.card-link {
  height: 100%;
  width: 100%;
}

.card-action-area {
  height: 100%;
}

.leaflet-top, .leaflet-bottom {
  z-index: 1;
}

#plans-search-input {
  margin-top: 1em;
  font-family: Assistant !important;
}

#plans-search-input ::placeholder, #plans-search-input ::value {
  font-family: Assistant;
}

.plans-helper-text {
  display: block;
  text-align: center;
  margin: 1em;
}