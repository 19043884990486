html body {
    direction: rtl;
}

.single-line {
    display: flex;
}

.share-link {
    padding-left: 10px;
    padding-right: 10px;
}

.register-link {
    color: red;
}
