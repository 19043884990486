.filterChip {
  margin: 0 0 5px 15px;
}

.filterChip svg {
  margin: 0 -8px 0 4px;
}

.MuiLinearProgress-root {
  height: 2px !important;
}
