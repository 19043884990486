.map-title {
  position: relative;
  z-index: 9999;
  font-size: large;
  padding: 5px;
}

.map-title-placeholder {
	position: relative;
	z-index: 9999;
	font-size: large;
	padding: 5px;
	background-image: url("/images/treeCuttingBackground.svg");
	background-repeat: no-repeat;
	background-position: center;
	background-color: white;
}

.map-title-left {
  position: absolute;
  left: 10px;
  cursor: pointer
}

.map-title span {
  font-weight: bolder;
}

.map-title-placeholder span {
  font-weight: bolder;
}

.leaflet-control-container .leaflet-bottom {
  z-index: 9999;
}
